import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react";
import {
  updatetrendingCategorySorText,
  updateTrendingCategoryItemByInput,
} from "../../redux/counterSlice";
import { useDispatch } from "react-redux";
import {
  updateRenkingData,
  updateRenkingDataByBlockchain,
  updateRenkingDataByPostdate,
} from "../../redux/counterSlice";
import Image from "next/image";

const Recently_added_dropdown = ({ data, dropdownFor }) => {
  const [blockchainData, setBlockchainData] = useState([]);
  const [superchainData, setSuperchainData] = useState([]);
  const [isLoad, setLoad] = useState(true);
  const dispatch = useDispatch();
  const [currencyValFrom, setCurrencyValFrom] = useState("");
  const [currencyValTo, setCurrencyValTo] = useState("");
  const [sortActive, setsortActive] = useState(1);
  const [sortFilterText, setSortFilterText] = useState("");
  const [renkingCategoriesdropdownShow, setRenkingCategoriesDropdownShow] =
    useState(false);
  const [blockChaindropdownShow, setBlockChainDropdownShow] = useState(false);
  const [itemDateDropdown, setItemDateDropdown] = useState(false);
  const [categoryDropdown, setCategoryDropdown] = useState(false);
  const [dropdownSale, setDropdownSale] = useState(false);
  const [currencyDropdown, setCurrencyDropdown] = useState(false);

  const handleRenkingCategoriesDropdown = () => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".rankingCategoriesDropdown")) {
        if (renkingCategoriesdropdownShow) {
          setRenkingCategoriesDropdownShow(false);
        } else {
          setRenkingCategoriesDropdownShow(true);
        }
      } else {
        setRenkingCategoriesDropdownShow(false);
      }
    });
  };
  const handleBlockChainDropdown = () => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".blockchainDropdown")) {
        if (blockChaindropdownShow) {
          setBlockChainDropdownShow(false);
        } else {
          setBlockChainDropdownShow(true);
        }
      } else {
        setBlockChainDropdownShow(false);
      }
    });
  };

  const handleItemDateDropdown = () => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".itemDateDropdown")) {
        if (itemDateDropdown) {
          setItemDateDropdown(false);
        } else {
          setItemDateDropdown(true);
        }
      } else {
        setItemDateDropdown(false);
      }
    });
  };

  const handleCategoryDropdown = () => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".category-dropdown")) {
        if (categoryDropdown) {
          setCategoryDropdown(false);
        } else {
          setCategoryDropdown(true);
        }
      } else {
        setCategoryDropdown(false);
      }
    });
  };

  const handleSaleDropdown = () => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".dropdown-sale")) {
        if (dropdownSale) {
          setDropdownSale(false);
        } else {
          setDropdownSale(true);
        }
      } else {
        setDropdownSale(false);
      }
    });
  };

  const handleCurrencyDropdown = () => {
    // console.log("first");
    if (currencyDropdown) {
      setCurrencyDropdown(false);
    } else {
      setCurrencyDropdown(true);
    }
  };

  // // console.log(blockChaindropdownShow);

  useEffect(() => {
    dispatch(updatetrendingCategorySorText(sortFilterText));
  }, [sortFilterText, dispatch]);

  const inputData = [
    {
      id: 1,
      text: "Brands Only",
    },
    {
      id: 2,
      text: "Influencers Only",
    },
  ];

  const handleInput = (e, text) => {
    if (e.target.checked) {
      // // console.log(text);
      dispatch(updateTrendingCategoryItemByInput(text));
    } else {
      dispatch(updateTrendingCategoryItemByInput(""));
    }
  };

  const handleCurrencyValTo = (e) => {
    const value = e.target.value;
    if (value < 0) {
      setCurrencyValTo(0);
    } else {
      setCurrencyValTo(value);
    }
  };
  const handleCurrencyValFrom = (e) => {
    const value = e.target.value;
    if (value < 0) {
      setCurrencyValFrom(0);
    } else {
      setCurrencyValFrom(value);
    }
  };

  useEffect(() => {
    const fetchBlockchainData = async () => {
      try {
        const response = await fetch(
          "https://api.impactoverse.com/api/app/categories?kind=CAMPAIGN"
        );
        const newData = await response.json();
        const tempArray = [];

        for (let index = 0; index < newData.categoriesList.length; index++) {
          const obj = {
            id: index,
            text: newData.categoriesList[index], // Fix here: use newData.categoriesList[index]
          };
          tempArray.push(obj);
        }

        // console.log("array data", tempArray);

        // Assuming categoriesList is an array in your data
        // console.log(newData);
        setBlockchainData(tempArray);
        // console.log("This is the message Element", newData.message);
        setLoad(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBlockchainData();
  }, []);

  useEffect(() => {
    const fetchSuperchainData = async () => {
      try {
        const res = await fetch(
          "https://api.impactoverse.com/api/app/super-campaign-names"
        );
        const superData = await res.json();
        const superArray = [];

        for (
          let index = 0;
          index < superData.superCampaignNames.length;
          index++
        ) {
          // console.log(
          //   "This is the Data of campaign",
          //   superData.superCampaignNames[index]
          // );
          const obj1 = {
            id: index,
            text: superData.superCampaignNames[index].superCampaignName, // Fix here: use newData.categoriesList[index]
          };
          superArray.push(obj1);
        }

        // console.log("array data", superArray);

        // Assuming categoriesList is an array in your data
        // console.log(superData);
        setSuperchainData(superArray);
        // console.log("This is the message Element", superData.success);
        // setLoad(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSuperchainData();
  }, []);

  if (dropdownFor === "recently_added") {
    return (
      <div>
        {/* dropdown */}
        <div className="dropdown relative my-1 cursor-pointer">
          <Tippy
            animation="fade"
            arrow={false}
            trigger="click"
            interactive="true"
            placement="bottom"
            className="tooltip-container"
            content={
              <div
                className="dropdown-menu dark:bg-jacarta-800 z-10 hidden min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show text-jacarta-500"
                aria-labelledby="categoriesSort"
              >
                <span className="font-display text-jacarta-300 block px-5 py-2 text-sm font-semibold">
                  Sort By
                </span>
                {data.map((item) => {
                  const { id, text } = item;
                  return (
                    <button
                      key={id}
                      className="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                      onClick={() => {
                        setsortActive(id);

                        setSortFilterText(text);
                      }}
                    >
                      {text}
                      {sortActive === id && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className="fill-accent mb-[3px] h-4 w-4"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                        </svg>
                      )}
                    </button>
                  );
                })}

                <span className="font-display text-jacarta-300 block px-5 py-2 text-sm font-semibold">
                  Options
                </span>
                {inputData.map(({ id, text }) => {
                  return (
                    <div
                      key={id}
                      className="dropdown-item font-display dark:hover:bg-jacarta-600 hover:bg-jacarta-50 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                    >
                      <span className="flex items-center justify-between">
                        <span>{text}</span>
                        <input
                          type="checkbox"
                          name="check"
                          className="checked:bg-accent checked:focus:bg-accent checked:hover:bg-accent after:bg-jacarta-400 bg-jacarta-100 relative h-4 w-7 cursor-pointer appearance-none rounded-lg border-none shadow-none after:absolute after:top-0.5 after:left-0.5 after:h-3 after:w-3 after:rounded-full after:transition-all checked:bg-none checked:after:left-3.5 checked:after:bg-white focus:ring-transparent focus:ring-offset-0"
                          onChange={(e) => handleInput(e, text)}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            }
          >
            <div className="dark:bg-jacarta-700 dropdown-toggle border-jacarta-100 dark:border-jacarta-600 inline-flex w-48 items-center justify-between rounded-lg border bg-white py-2 px-3 text-sm dark:text-white">
              <span className="font-display">Trending</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-jacarta-500 h-4 w-4 dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
              </svg>
            </div>
          </Tippy>
        </div>
      </div>
    );
  } else if (dropdownFor === "blockchain") {
    return (
      <div className="my-1 mr-2.5 relative">
        <button
          className="group dropdown-toggle blockchainDropdown dark:border-jacarta-600 dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent border-jacarta-100 font-display text-jacarta-700 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white"
          onClick={handleBlockChainDropdown}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            zoomAndPan="magnify"
            viewBox="0 0 45 44.999999"
            height="60"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
            className="fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-6 w-6 transition-colors group-hover:fill-white"
          >
            <defs>
              <clipPath id="d11c128308">
                <path
                  d="M 2.625 4 L 32 4 L 32 41 L 2.625 41 Z M 2.625 4 "
                  clipRule="nonzero"
                />
              </clipPath>
              <clipPath id="d384e1589d">
                <path
                  d="M 2.625 0 L 32 0 L 32 7 L 2.625 7 Z M 2.625 0 "
                  clipRule="nonzero"
                />
              </clipPath>
              <clipPath id="5e6ef96cde">
                <path
                  d="M 2.625 38 L 32 38 L 32 45 L 2.625 45 Z M 2.625 38 "
                  clipRule="nonzero"
                />
              </clipPath>
              <clipPath id="1397b892a3">
                <path
                  d="M 38 14 L 42.375 14 L 42.375 21 L 38 21 Z M 38 14 "
                  clipRule="nonzero"
                />
              </clipPath>
            </defs>
            <g clipPath="url(#d11c128308)">
              <path
                d="M 30.269531 40.433594 L 3.371094 40.433594 C 2.925781 40.433594 2.625 40.132812 2.625 39.683594 L 2.625 5.316406 C 2.625 4.867188 2.925781 4.566406 3.371094 4.566406 L 30.269531 4.566406 C 30.71875 4.566406 31.019531 4.867188 31.019531 5.316406 L 31.019531 11.292969 C 31.019531 11.742188 30.71875 12.039062 30.269531 12.039062 C 29.824219 12.039062 29.523438 11.742188 29.523438 11.292969 L 29.523438 6.0625 L 4.121094 6.0625 L 4.121094 38.9375 L 29.523438 38.9375 L 29.523438 37.445312 C 29.523438 36.996094 29.824219 36.695312 30.269531 36.695312 C 30.71875 36.695312 31.019531 36.996094 31.019531 37.445312 L 31.019531 39.683594 C 31.019531 40.132812 30.71875 40.433594 30.269531 40.433594 Z M 30.269531 40.433594 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <g clipPath="url(#d384e1589d)">
              <path
                d="M 30.269531 6.0625 L 3.371094 6.0625 C 2.925781 6.0625 2.625 5.761719 2.625 5.316406 L 2.625 2.324219 C 2.625 1.054688 3.597656 0.0859375 4.867188 0.0859375 L 28.777344 0.0859375 C 30.046875 0.0859375 31.019531 1.054688 31.019531 2.324219 L 31.019531 5.316406 C 31.019531 5.761719 30.71875 6.0625 30.269531 6.0625 Z M 4.121094 4.566406 L 29.523438 4.566406 L 29.523438 2.324219 C 29.523438 1.878906 29.226562 1.578125 28.777344 1.578125 L 4.867188 1.578125 C 4.417969 1.578125 4.121094 1.878906 4.121094 2.324219 Z M 4.121094 4.566406 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <path
              d="M 18.316406 3.820312 L 15.328125 3.820312 C 14.878906 3.820312 14.578125 3.523438 14.578125 3.074219 C 14.578125 2.625 14.878906 2.324219 15.328125 2.324219 L 18.316406 2.324219 C 18.765625 2.324219 19.0625 2.625 19.0625 3.074219 C 19.0625 3.523438 18.765625 3.820312 18.316406 3.820312 Z M 18.316406 3.820312 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <g clipPath="url(#5e6ef96cde)">
              <path
                d="M 28.777344 44.914062 L 4.867188 44.914062 C 3.597656 44.914062 2.625 43.945312 2.625 42.675781 L 2.625 39.683594 C 2.625 39.238281 2.925781 38.9375 3.371094 38.9375 L 30.269531 38.9375 C 30.71875 38.9375 31.019531 39.238281 31.019531 39.683594 L 31.019531 42.675781 C 31.019531 43.945312 30.046875 44.914062 28.777344 44.914062 Z M 4.121094 40.433594 L 4.121094 42.675781 C 4.121094 43.121094 4.417969 43.421875 4.867188 43.421875 L 28.777344 43.421875 C 29.226562 43.421875 29.523438 43.121094 29.523438 42.675781 L 29.523438 40.433594 Z M 4.121094 40.433594 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <path
              d="M 18.316406 42.675781 L 15.328125 42.675781 C 14.878906 42.675781 14.578125 42.375 14.578125 41.925781 C 14.578125 41.476562 14.878906 41.179688 15.328125 41.179688 L 18.316406 41.179688 C 18.765625 41.179688 19.0625 41.476562 19.0625 41.925781 C 19.0625 42.375 18.765625 42.675781 18.316406 42.675781 Z M 18.316406 42.675781 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 26.011719 22.722656 C 24.890625 22.722656 23.917969 21.828125 23.769531 20.707031 L 23.472656 17.71875 C 23.320312 16.523438 24.21875 15.402344 25.488281 15.253906 L 29.226562 14.878906 L 29.972656 22.351562 L 26.234375 22.722656 C 26.160156 22.722656 26.085938 22.722656 26.011719 22.722656 Z M 27.878906 16.523438 L 25.636719 16.746094 C 25.265625 16.820312 24.890625 17.121094 24.964844 17.570312 L 25.265625 20.558594 C 25.339844 20.929688 25.710938 21.230469 26.085938 21.230469 L 28.328125 21.003906 Z M 27.878906 16.523438 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 38.265625 25.265625 C 38.042969 25.265625 37.816406 25.265625 37.59375 25.191406 L 28.402344 22.277344 L 27.730469 15.328125 L 36.171875 10.695312 C 36.847656 10.320312 37.667969 10.320312 38.265625 10.695312 C 38.9375 11.066406 39.386719 11.742188 39.460938 12.488281 L 40.507812 22.875 C 40.582031 23.621094 40.28125 24.367188 39.683594 24.816406 C 39.238281 25.039062 38.789062 25.265625 38.265625 25.265625 Z M 38.042969 23.695312 C 38.265625 23.769531 38.5625 23.695312 38.714844 23.546875 C 38.9375 23.398438 39.011719 23.171875 39.011719 22.875 L 37.964844 12.488281 C 37.964844 12.261719 37.816406 12.039062 37.59375 11.890625 C 37.367188 11.742188 37.144531 11.742188 36.921875 11.890625 L 29.375 16.074219 L 29.898438 21.003906 Z M 38.042969 23.695312 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <g clipPath="url(#1397b892a3)">
              <path
                d="M 38.789062 20.707031 L 38.191406 14.730469 L 38.9375 14.65625 C 40.582031 14.503906 42.074219 15.699219 42.226562 17.34375 C 42.375 18.988281 41.179688 20.484375 39.535156 20.632812 Z M 39.835938 16.296875 L 40.058594 18.839844 C 40.507812 18.539062 40.730469 18.015625 40.65625 17.492188 C 40.65625 16.972656 40.28125 16.523438 39.835938 16.296875 Z M 39.835938 16.296875 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <path
              d="M 28.394531 21.757812 L 29.878906 21.609375 L 30.207031 24.957031 L 28.722656 25.101562 Z M 28.394531 21.757812 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 31.441406 22.730469 L 32.929688 22.582031 L 33.132812 24.664062 L 31.644531 24.8125 Z M 31.441406 22.730469 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 15.328125 25.488281 C 11.21875 25.488281 7.855469 22.125 7.855469 18.015625 C 7.855469 13.90625 11.21875 10.546875 15.328125 10.546875 C 19.4375 10.546875 22.800781 13.90625 22.800781 18.015625 C 22.800781 22.125 19.4375 25.488281 15.328125 25.488281 Z M 15.328125 12.039062 C 12.039062 12.039062 9.351562 14.730469 9.351562 18.015625 C 9.351562 21.304688 12.039062 23.996094 15.328125 23.996094 C 18.613281 23.996094 21.304688 21.304688 21.304688 18.015625 C 21.304688 14.730469 18.613281 12.039062 15.328125 12.039062 Z M 15.328125 12.039062 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 25.039062 40.433594 L 7.855469 40.433594 C 7.40625 40.433594 7.109375 40.132812 7.109375 39.683594 L 7.109375 30.71875 C 7.109375 27.804688 9.425781 25.488281 12.339844 25.488281 L 16.074219 25.488281 C 19.210938 25.488281 22.125 26.984375 23.996094 29.523438 C 24.21875 29.824219 24.144531 30.34375 23.84375 30.570312 C 23.546875 30.792969 23.023438 30.71875 22.800781 30.421875 C 21.230469 28.253906 18.6875 26.984375 16.074219 26.984375 L 12.339844 26.984375 C 10.246094 26.984375 8.601562 28.625 8.601562 30.71875 L 8.601562 38.9375 L 24.292969 38.9375 L 24.292969 35.203125 C 24.292969 34.976562 24.292969 34.753906 24.292969 34.53125 C 24.292969 34.082031 24.59375 33.78125 24.964844 33.707031 C 25.414062 33.632812 25.710938 34.007812 25.789062 34.378906 C 25.789062 34.679688 25.789062 34.902344 25.789062 35.203125 L 25.789062 39.683594 C 25.789062 40.132812 25.488281 40.433594 25.039062 40.433594 Z M 25.039062 40.433594 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 28.777344 35.203125 L 16.074219 35.203125 C 14.429688 35.203125 13.085938 33.855469 13.085938 32.214844 C 13.085938 30.570312 14.429688 29.226562 16.074219 29.226562 L 27.28125 29.226562 C 27.730469 29.226562 28.027344 28.925781 28.027344 28.476562 L 28.027344 26.234375 C 28.027344 24.964844 29 23.996094 30.269531 23.996094 L 31.765625 23.996094 C 33.035156 23.996094 34.007812 24.964844 34.007812 26.234375 L 34.007812 29.972656 C 34.007812 32.886719 31.691406 35.203125 28.777344 35.203125 Z M 16.074219 30.71875 C 15.253906 30.71875 14.578125 31.390625 14.578125 32.214844 C 14.578125 33.035156 15.253906 33.707031 16.074219 33.707031 L 28.777344 33.707031 C 30.867188 33.707031 32.511719 32.0625 32.511719 29.972656 L 32.511719 26.234375 C 32.511719 25.789062 32.214844 25.488281 31.765625 25.488281 L 30.269531 25.488281 C 29.824219 25.488281 29.523438 25.789062 29.523438 26.234375 L 29.523438 28.476562 C 29.523438 29.746094 28.550781 30.71875 27.28125 30.71875 Z M 16.074219 30.71875 "
              fillOpacity="1"
              fillRule="nonzero"
            />
          </svg>
          <span>Campaigns</span>
        </button>

        <div
          className={
            blockChaindropdownShow
              ? "dropdown-menu dark:bg-jacarta-800 z-10 min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show absolute top-full right-0"
              : "dropdown-menu dark:bg-jacarta-800 z-10 min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl hidden absolute top-full right-0"
          }
        >
          <ul className="flex flex-col flex-wrap">
            {data.map(({ id, text }) => {
              return (
                <li key={id} onClick={() => setsortActive(id)}>
                  <button
                    className="dropdown-item font-display dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                    onClick={() =>
                      dispatch(updateRenkingDataByBlockchain(text))
                    }
                  >
                    <span className="text-jacarta-700 dark:text-white">
                      {text}
                    </span>
                    {sortActive === id && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="fill-accent mb-[3px] h-4 w-4"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                      </svg>
                    )}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  } else if (dropdownFor === "category") {
    if (isLoad === true) {
      // // console.log('loading...', blockchainData);
    } else {
      // // console.log('false', blockchainData);
      // // console.log('someDat', data);
      // const myVariable = data;
      // const variableType = typeof myVariable;
      // // console.log(`The type of myVariable is: ${variableType}`);
    }
    return (
      <div className="my-1 mr-2.5 relative">
        <button
          className="group dropdown-toggle category-dropdown dark:border-jacarta-600 dark:bg-jacarta-700 dark:hover:bg-accent hover:bg-accent border-jacarta-100 font-display text-jacarta-700 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white"
          onClick={handleCategoryDropdown}
        >
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            zoomAndPan="magnify"
            viewBox="0 0 30 30.000001"
            height="50"
            className="fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
          >
            <defs>
              <clipPath id="bec604125b">
                <path d="M 7 1.914062 L 27.800781 1.914062 L 27.800781 20 L 7 20 Z M 7 1.914062 " />
              </clipPath>
              <clipPath id="3126e5c55e">
                <path d="M 2.398438 11 L 22 11 L 22 27.316406 L 2.398438 27.316406 Z M 2.398438 11 " />
              </clipPath>
              <clipPath id="84b3386788">
                <path d="M 2.398438 2 L 7 2 L 7 10 L 2.398438 10 Z M 2.398438 2 " />
              </clipPath>
              <clipPath id="20707254a9">
                <path d="M 22 19 L 27 19 L 27 27.316406 L 22 27.316406 Z M 22 19 " />
              </clipPath>
              <clipPath id="c2e0913146">
                <path d="M 26 1.914062 L 27.800781 1.914062 L 27.800781 3 L 26 3 Z M 26 1.914062 " />
              </clipPath>
              <clipPath id="689910eec8">
                <path d="M 2.398438 26 L 4 26 L 4 27.316406 L 2.398438 27.316406 Z M 2.398438 26 " />
              </clipPath>
            </defs>
            <g clipPath="url(#bec604125b)">
              <path
                d="M 15.101562 1.914062 C 12.402344 1.914062 9.828125 2.75 7.652344 4.324219 L 8.152344 5.011719 C 10.179688 3.539062 12.585938 2.761719 15.101562 2.761719 C 21.636719 2.761719 26.953125 8.078125 26.953125 14.617188 C 26.953125 16.171875 26.65625 17.6875 26.074219 19.113281 L 26.855469 19.433594 C 27.484375 17.90625 27.800781 16.285156 27.800781 14.617188 C 27.800781 7.613281 22.105469 1.914062 15.101562 1.914062 Z M 15.101562 1.914062 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <g clipPath="url(#3126e5c55e)">
              <path
                d="M 15.101562 27.316406 C 17.246094 27.316406 19.367188 26.773438 21.230469 25.742188 L 20.824219 25 C 19.082031 25.960938 17.101562 26.472656 15.101562 26.472656 C 8.5625 26.472656 3.246094 21.152344 3.246094 14.617188 C 3.246094 13.566406 3.382812 12.523438 3.652344 11.519531 L 2.835938 11.296875 C 2.546875 12.375 2.398438 13.492188 2.398438 14.617188 C 2.398438 21.621094 8.097656 27.316406 15.101562 27.316406 Z M 15.101562 27.316406 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <path
              d="M 15.101562 6.148438 C 10.429688 6.148438 6.632812 9.945312 6.632812 14.617188 C 6.632812 17.898438 8.554688 20.914062 11.535156 22.296875 L 11.890625 21.53125 C 9.210938 20.285156 7.480469 17.570312 7.480469 14.617188 C 7.480469 10.414062 10.898438 6.996094 15.101562 6.996094 C 19.300781 6.996094 22.722656 10.414062 22.722656 14.617188 C 22.722656 17.570312 20.988281 20.285156 18.308594 21.53125 L 18.664062 22.296875 C 21.644531 20.914062 23.566406 17.898438 23.566406 14.617188 C 23.566406 9.945312 19.769531 6.148438 15.101562 6.148438 Z M 15.101562 6.148438 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <g clipPath="url(#84b3386788)">
              <path
                d="M 5.785156 9.957031 L 5.785156 7.417969 L 6.632812 7.417969 L 6.632812 5.015625 C 6.632812 4.558594 6.253906 4.191406 5.785156 4.191406 L 5.355469 4.191406 C 5.6875 3.949219 5.875 3.53125 5.742188 3.074219 C 5.648438 2.738281 5.359375 2.460938 5.011719 2.375 C 4.304688 2.195312 3.667969 2.707031 3.667969 3.367188 C 3.667969 3.707031 3.839844 4.003906 4.097656 4.191406 L 3.667969 4.191406 C 3.199219 4.191406 2.820312 4.558594 2.820312 5.015625 L 2.820312 7.417969 L 3.667969 7.417969 L 3.667969 9.957031 Z M 5.785156 9.957031 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <g clipPath="url(#20707254a9)">
              <path
                d="M 25.644531 20.316406 C 25.546875 19.976562 25.261719 19.703125 24.910156 19.613281 C 24.203125 19.433594 23.566406 19.949219 23.566406 20.609375 C 23.566406 20.945312 23.738281 21.246094 23.996094 21.433594 L 23.566406 21.433594 C 23.101562 21.433594 22.722656 21.800781 22.722656 22.257812 L 22.722656 24.660156 L 23.566406 24.660156 L 23.566406 27.199219 L 25.683594 27.199219 L 25.683594 24.660156 L 26.53125 24.660156 L 26.53125 22.257812 C 26.53125 21.800781 26.152344 21.433594 25.683594 21.433594 L 25.253906 21.433594 C 25.585938 21.191406 25.773438 20.769531 25.644531 20.316406 Z M 25.644531 20.316406 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <g clipPath="url(#c2e0913146)">
              <path
                d="M 26.953125 1.914062 L 27.800781 1.914062 L 27.800781 2.761719 L 26.953125 2.761719 Z M 26.953125 1.914062 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <path
              d="M 25.683594 3.183594 L 26.53125 3.183594 L 26.53125 4.03125 L 25.683594 4.03125 Z M 25.683594 3.183594 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 3.667969 25.199219 L 4.515625 25.199219 L 4.515625 26.046875 L 3.667969 26.046875 Z M 3.667969 25.199219 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <g clipPath="url(#689910eec8)">
              <path
                d="M 2.398438 26.472656 L 3.246094 26.472656 L 3.246094 27.316406 L 2.398438 27.316406 Z M 2.398438 26.472656 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <path
              d="M 18.910156 17.15625 L 18.910156 13.132812 C 18.910156 12.082031 18.058594 11.230469 17.007812 11.230469 L 16.210938 11.230469 C 16.660156 10.835938 16.90625 10.214844 16.742188 9.539062 C 16.59375 8.9375 16.101562 8.449219 15.496094 8.308594 C 14.386719 8.058594 13.40625 8.894531 13.40625 9.957031 C 13.40625 10.46875 13.636719 10.917969 13.992188 11.230469 L 13.195312 11.230469 C 12.140625 11.230469 11.289062 12.082031 11.289062 13.132812 L 11.289062 17.15625 L 12.558594 17.15625 L 12.558594 13.734375 C 12.558594 13.347656 12.710938 12.980469 12.984375 12.710938 L 12.984375 23.082031 L 14.886719 23.082031 L 14.886719 17.15625 L 15.3125 17.15625 L 15.3125 23.082031 L 17.21875 23.082031 L 17.21875 12.710938 C 17.488281 12.980469 17.640625 13.773438 17.640625 14.15625 L 17.640625 17.15625 Z M 18.910156 17.15625 "
              fillOpacity="1"
              fillRule="nonzero"
            />
          </svg> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            zoomAndPan="magnify"
            viewBox="0 0 45 44.999999"
            height="60"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
            className="fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-6 w-6 transition-colors group-hover:fill-white"
          >
            <path
              d="M 22.5 0 C 17.726562 0 13.164062 1.476562 9.308594 4.269531 L 10.191406 5.484375 C 13.785156 2.878906 18.042969 1.5 22.5 1.5 C 34.078125 1.5 43.5 10.921875 43.5 22.5 C 43.5 25.257812 42.972656 27.9375 41.9375 30.464844 L 43.324219 31.035156 C 44.4375 28.324219 45 25.453125 45 22.5 C 45 10.09375 34.90625 0 22.5 0 Z M 22.5 0 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 22.5 45 C 26.300781 45 30.054688 44.035156 33.363281 42.207031 L 32.636719 40.894531 C 29.554688 42.597656 26.046875 43.5 22.5 43.5 C 10.921875 43.5 1.5 34.078125 1.5 22.5 C 1.5 20.636719 1.742188 18.792969 2.222656 17.011719 L 0.777344 16.621094 C 0.261719 18.527344 0 20.503906 0 22.5 C 0 34.90625 10.09375 45 22.5 45 Z M 22.5 45 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 22.5 7.5 C 14.230469 7.5 7.5 14.230469 7.5 22.5 C 7.5 28.316406 10.910156 33.65625 16.183594 36.109375 L 16.816406 34.75 C 12.066406 32.542969 9 27.734375 9 22.5 C 9 15.054688 15.054688 9 22.5 9 C 29.945312 9 36 15.054688 36 22.5 C 36 27.734375 32.933594 32.542969 28.183594 34.75 L 28.816406 36.109375 C 34.089844 33.65625 37.5 28.316406 37.5 22.5 C 37.5 14.230469 30.769531 7.5 22.5 7.5 Z M 22.5 7.5 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 6 14.25 L 6 9.75 L 7.5 9.75 L 7.5 5.492188 C 7.5 4.6875 6.828125 4.035156 6 4.035156 L 5.238281 4.035156 C 5.828125 3.609375 6.15625 2.863281 5.925781 2.054688 C 5.753906 1.457031 5.246094 0.972656 4.628906 0.8125 C 3.375 0.496094 2.25 1.40625 2.25 2.574219 C 2.25 3.171875 2.550781 3.703125 3.011719 4.035156 L 2.25 4.035156 C 1.421875 4.035156 0.75 4.6875 0.75 5.492188 L 0.75 9.75 L 2.25 9.75 L 2.25 14.25 Z M 6 14.25 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 41.175781 32.597656 C 41.003906 31.996094 40.496094 31.511719 39.878906 31.355469 C 38.625 31.039062 37.5 31.949219 37.5 33.117188 C 37.5 33.714844 37.800781 34.242188 38.261719 34.574219 L 37.5 34.574219 C 36.671875 34.574219 36 35.226562 36 36.035156 L 36 40.292969 L 37.5 40.292969 L 37.5 44.792969 L 41.25 44.792969 L 41.25 40.292969 L 42.75 40.292969 L 42.75 36.035156 C 42.75 35.226562 42.078125 34.574219 41.25 34.574219 L 40.488281 34.574219 C 41.078125 34.148438 41.40625 33.402344 41.175781 32.597656 Z M 41.175781 32.597656 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 43.5 0 L 45 0 L 45 1.5 L 43.5 1.5 Z M 43.5 0 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 41.25 2.25 L 42.75 2.25 L 42.75 3.75 L 41.25 3.75 Z M 41.25 2.25 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 2.25 41.25 L 3.75 41.25 L 3.75 42.75 L 2.25 42.75 Z M 2.25 41.25 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 0 43.5 L 1.5 43.5 L 1.5 45 L 0 45 Z M 0 43.5 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 29.25 27 L 29.25 19.871094 C 29.25 18.007812 27.742188 16.5 25.878906 16.5 L 24.464844 16.5 C 25.261719 15.804688 25.699219 14.707031 25.410156 13.507812 C 25.148438 12.4375 24.273438 11.574219 23.199219 11.328125 C 21.238281 10.886719 19.5 12.363281 19.5 14.25 C 19.5 15.152344 19.90625 15.949219 20.535156 16.5 L 19.121094 16.5 C 17.257812 16.5 15.75 18.007812 15.75 19.871094 L 15.75 27 L 18 27 L 18 20.933594 C 18 20.257812 18.269531 19.605469 18.75 19.125 L 18.75 37.5 L 22.125 37.5 L 22.125 27 L 22.875 27 L 22.875 37.5 L 26.25 37.5 L 26.25 19.125 C 26.730469 19.605469 27 21.007812 27 21.683594 L 27 27 Z M 29.25 27 "
              fillOpacity="1"
              fillRule="nonzero"
            />
          </svg>
          <span>Impact Category</span>
        </button>

        <div
          className={
            categoryDropdown
              ? "dropdown-menu dark:bg-jacarta-800 z-10 min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show absolute top-full right-0"
              : "dropdown-menu dark:bg-jacarta-800 z-10 min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl hidden absolute top-full right-0"
          }
        >
          <ul className="flex flex-col flex-wrap">
            {blockchainData.map(({ id, text }) => {
              return (
                <li key={id} onClick={() => setsortActive(id)}>
                  <button className="dropdown-item font-display dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
                    <span className="text-jacarta-700 dark:text-white">
                      {text}
                      {/* <p>This is the output</p> */}
                    </span>
                    {sortActive === id && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="fill-accent mb-[3px] h-4 w-4"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                      </svg>
                    )}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
    // }
  } else if (dropdownFor === "rankingCategories") {
    return (
      <div className="my-1 mr-2.5 relative">
        <button
          className="group dropdown-toggle rankingCategoriesDropdown dark:border-jacarta-600 dark:bg-jacarta-700 dark:hover:bg-accent hover:bg-accent border-jacarta-100 font-display text-jacarta-700 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white"
          onClick={handleRenkingCategoriesDropdown}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M14 10v4h-4v-4h4zm2 0h5v4h-5v-4zm-2 11h-4v-5h4v5zm2 0v-5h5v4a1 1 0 0 1-1 1h-4zM14 3v5h-4V3h4zm2 0h4a1 1 0 0 1 1 1v4h-5V3zm-8 7v4H3v-4h5zm0 11H4a1 1 0 0 1-1-1v-4h5v5zM8 3v5H3V4a1 1 0 0 1 1-1h4z"></path>
          </svg>
          <span>all Category</span>
        </button>

        <div
          className={
            renkingCategoriesdropdownShow
              ? "dropdown-menu dark:bg-jacarta-800 z-10 min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show absolute top-full right-0"
              : "dropdown-menu dark:bg-jacarta-800 z-10 min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl absolute top-full right-0 hidden"
          }
        >
          <ul className="flex flex-col flex-wrap">
            {data.map(({ id, text }) => {
              return (
                <li key={id} onClick={() => setsortActive(id)}>
                  <button
                    className="dropdown-item font-display dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                    onClick={() => dispatch(updateRenkingData(text))}
                  >
                    <span className="text-jacarta-700 dark:text-white">
                      {text}
                    </span>
                    {sortActive === id && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="fill-accent mb-[3px] h-4 w-4"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                      </svg>
                    )}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  } else if (dropdownFor === "sale-type") {
    return (
      <div className="my-1 mr-2.5 relative">
        <button
          className="group dropdown-toggle dropdown-sale dark:border-jacarta-600 dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent border-jacarta-100 font-display text-jacarta-700 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white"
          onClick={handleSaleDropdown}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            zoomAndPan="magnify"
            viewBox="0 0 45 44.999999"
            height="60"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
            className="fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-7 w-7 transition-colors group-hover:fill-white"
          >
            <defs>
              <clipPath id="3b91e8ea8c">
                <path
                  d="M 1.75 10 L 17 10 L 17 40.707031 L 1.75 40.707031 Z M 1.75 10 "
                  clipRule="nonzero"
                />
              </clipPath>
              <clipPath id="7ff4871646">
                <path
                  d="M 8 6.207031 L 9 6.207031 L 9 14 L 8 14 Z M 8 6.207031 "
                  clipRule="nonzero"
                />
              </clipPath>
            </defs>
            <path
              d="M 34.964844 16.882812 L 35.035156 15.773438 L 35.046875 15.59375 L 35.046875 14.511719 L 35.546875 13.992188 C 35.582031 13.953125 35.59375 13.902344 35.582031 13.851562 L 35.367188 12.96875 C 35.347656 12.886719 35.269531 12.839844 35.1875 12.859375 C 35.109375 12.878906 35.0625 12.960938 35.082031 13.039062 L 35.277344 13.84375 L 34.84375 14.292969 L 34.191406 14.292969 C 34.15625 14.292969 34.125 14.308594 34.097656 14.328125 C 34.042969 14.375 33.769531 14.664062 33.847656 15.878906 C 33.855469 15.957031 33.917969 16.019531 33.996094 16.019531 C 34 16.019531 34.003906 16.019531 34.007812 16.019531 C 34.03125 16.015625 34.054688 16.007812 34.074219 15.996094 L 34.113281 16.578125 C 34.15625 16.570312 34.199219 16.566406 34.246094 16.566406 C 34.527344 16.566406 34.785156 16.6875 34.964844 16.882812 Z M 34.964844 16.882812 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 34.964844 13.765625 C 34.964844 13.535156 34.777344 13.347656 34.550781 13.347656 C 34.320312 13.347656 34.132812 13.535156 34.132812 13.765625 C 34.132812 14 34.320312 14.1875 34.550781 14.1875 C 34.777344 14.1875 34.964844 14 34.964844 13.765625 Z M 34.964844 13.765625 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 39.273438 17.273438 L 39.371094 15.730469 L 39.386719 15.539062 L 39.386719 14.382812 L 39.914062 13.828125 C 39.953125 13.785156 39.96875 13.730469 39.957031 13.675781 L 39.726562 12.734375 C 39.703125 12.648438 39.617188 12.597656 39.535156 12.617188 C 39.449219 12.640625 39.398438 12.726562 39.417969 12.8125 L 39.628906 13.667969 L 39.167969 14.148438 L 38.472656 14.148438 C 38.433594 14.148438 38.398438 14.164062 38.371094 14.1875 C 38.3125 14.234375 38.019531 14.546875 38.105469 15.84375 C 38.109375 15.925781 38.179688 15.992188 38.261719 15.992188 C 38.265625 15.992188 38.269531 15.992188 38.273438 15.992188 C 38.300781 15.992188 38.324219 15.980469 38.347656 15.96875 L 38.421875 17.140625 C 38.507812 17.117188 38.597656 17.105469 38.691406 17.105469 C 38.90625 17.105469 39.105469 17.167969 39.273438 17.273438 Z M 39.273438 17.273438 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 39.296875 13.585938 C 39.296875 13.710938 39.253906 13.816406 39.164062 13.90625 C 39.078125 13.992188 38.972656 14.035156 38.851562 14.035156 C 38.730469 14.035156 38.625 13.992188 38.539062 13.90625 C 38.453125 13.816406 38.410156 13.710938 38.410156 13.585938 C 38.410156 13.464844 38.453125 13.359375 38.539062 13.269531 C 38.625 13.183594 38.730469 13.140625 38.851562 13.140625 C 38.972656 13.140625 39.078125 13.183594 39.164062 13.269531 C 39.253906 13.359375 39.296875 13.464844 39.296875 13.585938 Z M 39.296875 13.585938 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 22.699219 13.757812 L 22.699219 14.636719 L 22.710938 14.785156 L 22.800781 16.234375 C 22.808594 16.335938 22.898438 16.414062 23 16.410156 C 23.039062 16.40625 23.078125 16.390625 23.105469 16.367188 C 23.132812 16.390625 23.171875 16.40625 23.210938 16.410156 C 23.3125 16.414062 23.402344 16.335938 23.410156 16.234375 L 23.488281 14.964844 C 23.507812 14.976562 23.527344 14.980469 23.546875 14.984375 C 23.550781 14.984375 23.550781 14.984375 23.554688 14.984375 C 23.617188 14.984375 23.667969 14.933594 23.675781 14.871094 C 23.738281 13.882812 23.515625 13.648438 23.472656 13.609375 L 23.472656 13.613281 C 23.449219 13.59375 23.425781 13.582031 23.394531 13.582031 L 22.867188 13.582031 L 22.515625 13.214844 L 22.675781 12.566406 C 22.691406 12.5 22.652344 12.433594 22.585938 12.417969 C 22.523438 12.402344 22.457031 12.441406 22.441406 12.507812 L 22.265625 13.222656 C 22.257812 13.261719 22.269531 13.304688 22.296875 13.335938 Z M 22.699219 13.757812 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 23.105469 13.496094 C 23.292969 13.496094 23.441406 13.34375 23.441406 13.15625 C 23.441406 12.964844 23.292969 12.8125 23.105469 12.8125 C 22.917969 12.8125 22.769531 12.964844 22.769531 13.15625 C 22.769531 13.34375 22.917969 13.496094 23.105469 13.496094 Z M 23.105469 13.496094 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 29.519531 13.550781 L 29.519531 13.800781 L 29.527344 13.949219 L 29.621094 15.398438 C 29.628906 15.503906 29.714844 15.582031 29.816406 15.574219 C 29.859375 15.570312 29.894531 15.554688 29.925781 15.53125 C 29.953125 15.554688 29.988281 15.570312 30.03125 15.574219 C 30.132812 15.582031 30.21875 15.503906 30.226562 15.398438 L 30.308594 14.128906 C 30.324219 14.140625 30.34375 14.148438 30.363281 14.148438 C 30.367188 14.148438 30.371094 14.148438 30.371094 14.148438 C 30.433594 14.148438 30.488281 14.097656 30.492188 14.035156 C 30.554688 13.046875 30.335938 12.8125 30.289062 12.773438 L 30.289062 12.777344 C 30.269531 12.757812 30.242188 12.746094 30.214844 12.746094 L 29.683594 12.746094 L 29.332031 12.378906 L 29.492188 11.730469 C 29.507812 11.664062 29.46875 11.597656 29.40625 11.582031 C 29.339844 11.566406 29.277344 11.605469 29.261719 11.671875 L 29.085938 12.386719 C 29.074219 12.429688 29.085938 12.472656 29.113281 12.5 L 29.519531 12.921875 Z M 29.519531 13.550781 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 29.925781 12.660156 C 30.109375 12.660156 30.261719 12.507812 30.261719 12.320312 C 30.261719 12.132812 30.109375 11.976562 29.925781 11.976562 C 29.738281 11.976562 29.585938 12.132812 29.585938 12.320312 C 29.585938 12.507812 29.738281 12.660156 29.925781 12.660156 Z M 29.925781 12.660156 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 42.972656 15.652344 L 42.644531 14.308594 C 42.613281 14.1875 42.492188 14.109375 42.371094 14.140625 C 42.25 14.171875 42.175781 14.292969 42.207031 14.417969 L 42.503906 15.636719 L 41.847656 16.324219 L 40.855469 16.324219 C 40.800781 16.324219 40.75 16.34375 40.710938 16.378906 C 40.625 16.445312 40.214844 16.886719 40.332031 18.738281 C 40.339844 18.859375 40.441406 18.949219 40.558594 18.949219 C 40.5625 18.949219 40.566406 18.949219 40.570312 18.949219 C 40.609375 18.949219 40.648438 18.933594 40.675781 18.914062 L 40.832031 21.296875 C 40.84375 21.488281 41.007812 21.636719 41.199219 21.625 C 41.273438 21.617188 41.34375 21.589844 41.398438 21.542969 C 41.453125 21.589844 41.519531 21.617188 41.597656 21.625 C 41.789062 21.636719 41.953125 21.488281 41.964844 21.296875 L 42.140625 18.578125 L 42.15625 18.300781 L 42.15625 16.65625 L 42.914062 15.863281 C 42.96875 15.808594 42.988281 15.726562 42.972656 15.652344 Z M 42.972656 15.652344 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 42.03125 15.523438 C 42.03125 15.699219 41.96875 15.851562 41.84375 15.976562 C 41.722656 16.101562 41.574219 16.164062 41.398438 16.164062 C 41.222656 16.164062 41.074219 16.101562 40.953125 15.976562 C 40.828125 15.851562 40.765625 15.699219 40.765625 15.523438 C 40.765625 15.347656 40.828125 15.195312 40.953125 15.070312 C 41.074219 14.945312 41.222656 14.882812 41.398438 14.882812 C 41.574219 14.882812 41.722656 14.945312 41.84375 15.070312 C 41.96875 15.195312 42.03125 15.347656 42.03125 15.523438 Z M 42.03125 15.523438 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 17.15625 15.433594 L 17.828125 16.136719 L 17.828125 17.589844 L 17.84375 17.832031 L 17.996094 20.234375 C 18.007812 20.40625 18.152344 20.535156 18.320312 20.527344 C 18.390625 20.519531 18.449219 20.496094 18.5 20.453125 C 18.546875 20.496094 18.605469 20.519531 18.675781 20.527344 C 18.84375 20.535156 18.988281 20.40625 19 20.234375 L 19.136719 18.132812 C 19.160156 18.148438 19.195312 18.160156 19.226562 18.164062 C 19.234375 18.164062 19.238281 18.164062 19.242188 18.164062 C 19.34375 18.164062 19.433594 18.082031 19.4375 17.976562 C 19.542969 16.339844 19.179688 15.949219 19.105469 15.890625 C 19.070312 15.863281 19.027344 15.84375 18.976562 15.84375 L 18.101562 15.84375 L 17.519531 15.234375 L 17.785156 14.15625 C 17.808594 14.050781 17.746094 13.941406 17.636719 13.914062 C 17.53125 13.886719 17.425781 13.953125 17.398438 14.0625 L 17.109375 15.246094 C 17.089844 15.316406 17.109375 15.386719 17.15625 15.433594 Z M 17.15625 15.433594 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 19.054688 15.132812 C 19.054688 15.289062 19.003906 15.425781 18.894531 15.535156 C 18.785156 15.644531 18.652344 15.699219 18.496094 15.699219 C 18.34375 15.699219 18.210938 15.644531 18.101562 15.535156 C 17.992188 15.425781 17.941406 15.289062 17.941406 15.132812 C 17.941406 14.980469 17.992188 14.84375 18.101562 14.734375 C 18.210938 14.625 18.34375 14.570312 18.496094 14.570312 C 18.652344 14.570312 18.785156 14.625 18.894531 14.734375 C 19.003906 14.84375 19.054688 14.980469 19.054688 15.132812 Z M 19.054688 15.132812 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 37.175781 15.589844 L 37.234375 14.695312 C 37.253906 14.710938 37.277344 14.722656 37.304688 14.722656 C 37.308594 14.722656 37.3125 14.722656 37.3125 14.722656 C 37.386719 14.722656 37.445312 14.667969 37.449219 14.59375 C 37.523438 13.472656 37.273438 13.207031 37.222656 13.164062 C 37.195312 13.144531 37.167969 13.132812 37.140625 13.132812 C 37.132812 13.132812 37.128906 13.132812 37.125 13.132812 L 36.46875 13.132812 C 36.460938 13.132812 36.457031 13.132812 36.453125 13.132812 C 36.425781 13.132812 36.394531 13.144531 36.371094 13.164062 C 36.320312 13.207031 36.070312 13.472656 36.140625 14.59375 C 36.148438 14.667969 36.207031 14.722656 36.277344 14.722656 C 36.28125 14.722656 36.285156 14.722656 36.285156 14.722656 C 36.3125 14.722656 36.339844 14.710938 36.359375 14.695312 L 36.398438 15.316406 C 36.441406 15.3125 36.484375 15.308594 36.53125 15.308594 C 36.785156 15.308594 37.011719 15.414062 37.175781 15.589844 Z M 37.175781 15.589844 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 37.179688 12.648438 C 37.179688 12.753906 37.140625 12.84375 37.066406 12.921875 C 36.992188 12.996094 36.902344 13.035156 36.796875 13.035156 C 36.691406 13.035156 36.601562 12.996094 36.523438 12.921875 C 36.449219 12.84375 36.414062 12.753906 36.414062 12.648438 C 36.414062 12.539062 36.449219 12.449219 36.523438 12.371094 C 36.601562 12.296875 36.691406 12.257812 36.796875 12.257812 C 36.902344 12.257812 36.992188 12.296875 37.066406 12.371094 C 37.140625 12.449219 37.179688 12.539062 37.179688 12.648438 Z M 37.179688 12.648438 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 27.878906 15.433594 L 27.898438 15.113281 C 27.917969 15.128906 27.945312 15.140625 27.972656 15.140625 C 27.972656 15.140625 27.976562 15.140625 27.980469 15.140625 C 28.050781 15.140625 28.109375 15.085938 28.117188 15.011719 C 28.1875 13.890625 27.9375 13.625 27.886719 13.582031 C 27.863281 13.5625 27.832031 13.550781 27.804688 13.550781 C 27.800781 13.550781 27.796875 13.550781 27.789062 13.550781 L 27.132812 13.550781 C 27.128906 13.550781 27.125 13.550781 27.121094 13.550781 C 27.089844 13.550781 27.0625 13.5625 27.039062 13.582031 C 26.984375 13.625 26.734375 13.890625 26.808594 15.011719 C 26.8125 15.085938 26.871094 15.140625 26.945312 15.140625 C 26.945312 15.140625 26.949219 15.140625 26.953125 15.140625 C 26.980469 15.140625 27.003906 15.128906 27.023438 15.113281 L 27.117188 16.5625 C 27.125 16.679688 27.226562 16.765625 27.339844 16.761719 C 27.386719 16.757812 27.429688 16.738281 27.460938 16.710938 C 27.496094 16.738281 27.535156 16.757812 27.582031 16.761719 C 27.59375 16.761719 27.609375 16.761719 27.621094 16.757812 C 27.507812 16.605469 27.4375 16.417969 27.4375 16.214844 C 27.4375 15.882812 27.617188 15.59375 27.878906 15.433594 Z M 27.878906 15.433594 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 27.84375 13.0625 C 27.84375 13.171875 27.808594 13.261719 27.734375 13.339844 C 27.65625 13.414062 27.566406 13.453125 27.460938 13.453125 C 27.355469 13.453125 27.265625 13.414062 27.191406 13.339844 C 27.117188 13.261719 27.078125 13.171875 27.078125 13.0625 C 27.078125 12.957031 27.117188 12.867188 27.191406 12.789062 C 27.265625 12.714844 27.355469 12.675781 27.460938 12.675781 C 27.566406 12.675781 27.65625 12.714844 27.734375 12.789062 C 27.808594 12.867188 27.84375 12.957031 27.84375 13.0625 Z M 27.84375 13.0625 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 26.035156 18.449219 L 26.035156 18.441406 C 26.03125 18.441406 26.03125 18.445312 26.027344 18.449219 Z M 26.035156 18.449219 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 25.136719 15.914062 L 25.300781 16.597656 C 25.382812 16.578125 25.464844 16.566406 25.546875 16.566406 C 25.824219 16.566406 26.070312 16.679688 26.25 16.863281 C 26.339844 16.859375 26.414062 16.789062 26.421875 16.695312 C 26.515625 15.242188 26.191406 14.894531 26.125 14.839844 C 26.09375 14.8125 26.054688 14.800781 26.015625 14.800781 C 26.011719 14.796875 26.003906 14.796875 26 14.796875 L 25.144531 14.796875 C 25.140625 14.796875 25.132812 14.796875 25.128906 14.800781 C 25.089844 14.800781 25.050781 14.8125 25.019531 14.839844 C 24.980469 14.875 24.828125 15.03125 24.753906 15.523438 C 24.9375 15.574219 25.085938 15.71875 25.136719 15.914062 Z M 25.136719 15.914062 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 26.070312 14.167969 C 26.070312 13.890625 25.847656 13.664062 25.574219 13.664062 C 25.296875 13.664062 25.074219 13.890625 25.074219 14.167969 C 25.074219 14.445312 25.296875 14.671875 25.574219 14.671875 C 25.847656 14.671875 26.070312 14.445312 26.070312 14.167969 Z M 26.070312 14.167969 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 21.972656 18.164062 C 21.996094 18.160156 22.019531 18.148438 22.039062 18.132812 C 22 18.015625 21.976562 17.890625 21.976562 17.757812 C 21.976562 17.550781 22.03125 17.355469 22.128906 17.183594 C 22.117188 16.867188 22.085938 16.625 22.039062 16.4375 L 21.691406 17.871094 Z M 21.972656 18.164062 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 20.386719 18.175781 C 20.390625 18.175781 20.398438 18.175781 20.402344 18.175781 C 20.441406 18.171875 20.476562 18.15625 20.507812 18.136719 L 20.644531 20.269531 C 20.65625 20.441406 20.804688 20.574219 20.972656 20.5625 C 21.042969 20.558594 21.101562 20.53125 21.152344 20.492188 C 21.199219 20.53125 21.261719 20.558594 21.328125 20.5625 C 21.5 20.574219 21.648438 20.441406 21.660156 20.269531 L 21.710938 19.433594 L 20.726562 18.402344 C 20.601562 18.265625 20.550781 18.082031 20.59375 17.902344 L 21.078125 15.914062 C 21.085938 15.882812 21.097656 15.855469 21.105469 15.832031 L 20.667969 15.832031 C 20.660156 15.832031 20.652344 15.832031 20.644531 15.832031 C 20.605469 15.832031 20.5625 15.847656 20.523438 15.878906 C 20.449219 15.9375 20.082031 16.332031 20.1875 17.984375 C 20.195312 18.09375 20.28125 18.175781 20.386719 18.175781 Z M 20.386719 18.175781 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 21.203125 15.679688 C 21.292969 15.582031 21.417969 15.519531 21.558594 15.507812 C 21.65625 15.40625 21.714844 15.269531 21.714844 15.113281 C 21.714844 14.796875 21.464844 14.542969 21.152344 14.542969 C 20.839844 14.542969 20.585938 14.796875 20.585938 15.113281 C 20.585938 15.429688 20.839844 15.6875 21.152344 15.6875 C 21.167969 15.6875 21.183594 15.683594 21.203125 15.679688 Z M 21.203125 15.679688 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 37.730469 18.800781 C 37.632812 18.636719 37.578125 18.441406 37.578125 18.234375 C 37.578125 18.089844 37.605469 17.953125 37.652344 17.828125 C 37.609375 17.660156 37.558594 17.535156 37.511719 17.441406 L 37.292969 18.34375 Z M 37.730469 18.800781 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 35.816406 21.773438 C 35.816406 21.796875 35.8125 21.816406 35.808594 21.835938 L 35.875 22.871094 C 35.890625 23.09375 36.078125 23.261719 36.300781 23.25 C 36.386719 23.242188 36.46875 23.207031 36.53125 23.15625 C 36.59375 23.207031 36.671875 23.242188 36.757812 23.25 C 36.980469 23.261719 37.171875 23.09375 37.183594 22.871094 L 37.363281 20.113281 C 37.371094 20.121094 37.382812 20.125 37.390625 20.132812 L 37.390625 19.964844 L 36.34375 18.867188 C 36.21875 18.734375 36.167969 18.550781 36.210938 18.371094 L 36.511719 17.136719 L 35.902344 17.136719 C 35.894531 17.136719 35.886719 17.136719 35.878906 17.140625 C 35.820312 17.140625 35.765625 17.160156 35.722656 17.199219 C 35.648438 17.257812 35.367188 17.554688 35.285156 18.570312 C 35.472656 18.746094 35.96875 19.429688 35.816406 21.773438 Z M 35.816406 21.773438 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 36.558594 16.949219 L 36.691406 16.414062 C 36.746094 16.175781 36.957031 16.011719 37.195312 16.011719 C 37.207031 16.011719 37.21875 16.015625 37.230469 16.015625 C 37.144531 15.703125 36.863281 15.476562 36.53125 15.476562 C 36.128906 15.476562 35.800781 15.804688 35.800781 16.214844 C 35.800781 16.621094 36.128906 16.949219 36.53125 16.949219 C 36.539062 16.949219 36.550781 16.949219 36.558594 16.949219 Z M 36.558594 16.949219 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 25.28125 18.617188 L 24.425781 19.511719 L 24.425781 21.878906 L 24.417969 22.039062 C 24.421875 22.039062 24.425781 22.042969 24.433594 22.042969 C 24.441406 22.042969 24.445312 22.042969 24.453125 22.039062 C 24.511719 22.039062 24.5625 22.015625 24.609375 21.984375 L 24.808594 25.101562 C 24.824219 25.351562 25.039062 25.546875 25.289062 25.527344 C 25.386719 25.523438 25.476562 25.484375 25.546875 25.421875 C 25.621094 25.484375 25.710938 25.523438 25.808594 25.527344 C 26.058594 25.546875 26.273438 25.351562 26.289062 25.101562 L 26.488281 21.984375 C 26.535156 22.015625 26.585938 22.039062 26.644531 22.039062 C 26.652344 22.042969 26.65625 22.042969 26.664062 22.042969 C 26.816406 22.042969 26.945312 21.921875 26.957031 21.765625 C 27.007812 20.992188 26.984375 20.40625 26.929688 19.96875 C 26.925781 19.957031 26.925781 19.945312 26.921875 19.933594 C 26.921875 19.925781 26.921875 19.921875 26.921875 19.917969 C 26.800781 19.023438 26.535156 18.746094 26.464844 18.683594 C 26.410156 18.640625 26.347656 18.617188 26.285156 18.617188 C 26.277344 18.617188 26.265625 18.617188 26.257812 18.617188 Z M 25.28125 18.617188 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 25.546875 16.734375 C 25.476562 16.734375 25.410156 16.746094 25.34375 16.761719 L 25.621094 17.902344 C 25.664062 18.082031 25.613281 18.265625 25.488281 18.402344 C 25.507812 18.402344 25.527344 18.402344 25.546875 18.402344 C 26.003906 18.402344 26.375 18.03125 26.375 17.570312 C 26.375 17.109375 26.003906 16.734375 25.546875 16.734375 Z M 25.546875 16.734375 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 32.820312 15.984375 C 32.851562 16.011719 32.886719 16.027344 32.929688 16.027344 C 33.03125 16.035156 33.121094 15.957031 33.128906 15.851562 L 33.210938 14.566406 C 33.226562 14.582031 33.25 14.589844 33.273438 14.589844 C 33.277344 14.589844 33.277344 14.589844 33.28125 14.589844 C 33.34375 14.589844 33.398438 14.542969 33.402344 14.476562 C 33.464844 13.480469 33.246094 13.242188 33.199219 13.207031 C 33.175781 13.1875 33.152344 13.179688 33.125 13.179688 C 33.121094 13.179688 33.117188 13.175781 33.113281 13.175781 L 32.53125 13.175781 C 32.527344 13.175781 32.519531 13.179688 32.515625 13.179688 C 32.492188 13.179688 32.464844 13.1875 32.445312 13.207031 C 32.398438 13.242188 32.175781 13.480469 32.242188 14.476562 C 32.246094 14.542969 32.296875 14.589844 32.363281 14.589844 C 32.363281 14.589844 32.367188 14.589844 32.371094 14.589844 C 32.394531 14.589844 32.414062 14.582031 32.433594 14.566406 L 32.515625 15.851562 C 32.515625 15.859375 32.519531 15.867188 32.519531 15.875 C 32.628906 15.886719 32.726562 15.929688 32.8125 15.992188 C 32.8125 15.988281 32.820312 15.988281 32.820312 15.984375 Z M 32.820312 15.984375 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 32.480469 12.746094 C 32.480469 12.9375 32.632812 13.089844 32.820312 13.089844 C 33.007812 13.089844 33.160156 12.9375 33.160156 12.746094 C 33.160156 12.554688 33.007812 12.402344 32.820312 12.402344 C 32.632812 12.402344 32.480469 12.554688 32.480469 12.746094 Z M 32.480469 12.746094 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 32.035156 18.351562 C 32.035156 18.574219 31.972656 18.785156 31.871094 18.96875 L 32.199219 18.625 L 32.230469 18.136719 C 32.242188 18.144531 32.257812 18.152344 32.269531 18.15625 L 31.890625 16.601562 C 31.851562 16.449219 31.878906 16.289062 31.957031 16.15625 C 32.023438 16.042969 32.128906 15.957031 32.25 15.910156 C 32.234375 15.894531 32.222656 15.882812 32.214844 15.878906 C 32.179688 15.847656 32.136719 15.832031 32.09375 15.832031 C 32.085938 15.832031 32.078125 15.832031 32.074219 15.832031 L 31.101562 15.832031 C 31.097656 15.832031 31.089844 15.832031 31.082031 15.832031 C 31.039062 15.832031 30.996094 15.847656 30.960938 15.878906 C 30.902344 15.925781 30.660156 16.183594 30.613281 17.105469 C 30.671875 17.09375 30.730469 17.089844 30.789062 17.089844 C 31.476562 17.089844 32.035156 17.65625 32.035156 18.351562 Z M 32.035156 18.351562 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 31.4375 19.421875 C 31.429688 19.425781 31.421875 19.433594 31.414062 19.4375 L 31.425781 19.4375 Z M 31.4375 19.421875 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 32.152344 15.113281 C 32.152344 15.273438 32.097656 15.40625 31.988281 15.519531 C 31.875 15.628906 31.742188 15.6875 31.585938 15.6875 C 31.433594 15.6875 31.296875 15.628906 31.1875 15.519531 C 31.078125 15.40625 31.023438 15.273438 31.023438 15.113281 C 31.023438 14.957031 31.078125 14.820312 31.1875 14.710938 C 31.296875 14.597656 31.433594 14.542969 31.585938 14.542969 C 31.742188 14.542969 31.875 14.597656 31.988281 14.710938 C 32.097656 14.820312 32.152344 14.957031 32.152344 15.113281 Z M 32.152344 15.113281 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 29.597656 18.855469 C 29.59375 18.792969 29.589844 18.730469 29.585938 18.671875 C 29.558594 18.570312 29.542969 18.460938 29.542969 18.351562 C 29.542969 18.324219 29.546875 18.304688 29.546875 18.28125 C 29.511719 18.035156 29.46875 17.84375 29.417969 17.695312 L 29.230469 18.472656 Z M 29.597656 18.855469 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 27.347656 20.167969 C 27.351562 20.167969 27.359375 20.167969 27.363281 20.164062 C 27.417969 20.164062 27.464844 20.144531 27.503906 20.113281 L 27.679688 22.871094 C 27.691406 23.09375 27.882812 23.261719 28.105469 23.25 C 28.191406 23.242188 28.269531 23.207031 28.332031 23.15625 C 28.398438 23.207031 28.476562 23.242188 28.5625 23.25 C 28.785156 23.261719 28.976562 23.09375 28.988281 22.871094 L 29.164062 20.113281 C 29.175781 20.121094 29.1875 20.128906 29.199219 20.132812 L 28.167969 19.054688 C 28.03125 18.910156 27.976562 18.703125 28.023438 18.503906 L 28.355469 17.136719 L 27.707031 17.136719 C 27.699219 17.136719 27.691406 17.136719 27.679688 17.140625 C 27.625 17.140625 27.570312 17.160156 27.523438 17.199219 C 27.429688 17.277344 26.953125 17.789062 27.089844 19.921875 C 27.097656 20.058594 27.210938 20.167969 27.347656 20.167969 Z M 27.347656 20.167969 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 28.402344 16.949219 L 28.558594 16.316406 C 28.609375 16.101562 28.777344 15.9375 28.988281 15.886719 C 28.867188 15.644531 28.621094 15.476562 28.332031 15.476562 C 27.929688 15.476562 27.605469 15.804688 27.605469 16.214844 C 27.605469 16.621094 27.929688 16.949219 28.332031 16.949219 C 28.355469 16.949219 28.378906 16.949219 28.402344 16.949219 Z M 28.402344 16.949219 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 33.40625 19.054688 L 32.933594 19.550781 C 32.832031 20.015625 32.769531 20.71875 32.835938 21.765625 C 32.847656 21.921875 32.976562 22.042969 33.128906 22.042969 C 33.136719 22.042969 33.140625 22.042969 33.148438 22.039062 C 33.207031 22.039062 33.261719 22.015625 33.304688 21.984375 L 33.503906 25.101562 C 33.519531 25.351562 33.734375 25.546875 33.984375 25.527344 C 34.082031 25.523438 34.171875 25.484375 34.246094 25.421875 C 34.316406 25.484375 34.40625 25.523438 34.503906 25.527344 C 34.753906 25.546875 34.96875 25.351562 34.984375 25.101562 L 35.183594 21.984375 C 35.230469 22.015625 35.28125 22.039062 35.339844 22.039062 C 35.347656 22.042969 35.355469 22.042969 35.359375 22.042969 C 35.511719 22.042969 35.640625 21.921875 35.652344 21.765625 C 35.808594 19.351562 35.269531 18.773438 35.160156 18.683594 C 35.105469 18.640625 35.042969 18.617188 34.984375 18.617188 C 34.972656 18.617188 34.960938 18.617188 34.953125 18.617188 L 33.570312 18.617188 C 33.578125 18.777344 33.523438 18.9375 33.40625 19.054688 Z M 33.40625 19.054688 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 35.070312 17.570312 C 35.070312 17.800781 34.988281 17.996094 34.828125 18.160156 C 34.667969 18.324219 34.472656 18.402344 34.246094 18.402344 C 34.015625 18.402344 33.824219 18.324219 33.660156 18.160156 C 33.5 17.996094 33.417969 17.800781 33.417969 17.570312 C 33.417969 17.339844 33.5 17.140625 33.660156 16.980469 C 33.824219 16.816406 34.015625 16.734375 34.246094 16.734375 C 34.472656 16.734375 34.667969 16.816406 34.828125 16.980469 C 34.988281 17.140625 35.070312 17.339844 35.070312 17.570312 Z M 35.070312 17.570312 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 15.214844 21.050781 L 16.667969 21.90625 L 17.011719 21.367188 L 14.511719 19.566406 C 14.546875 19.460938 14.5 19.335938 14.390625 19.285156 L 13.742188 18.972656 C 13.636719 18.921875 13.511719 18.957031 13.449219 19.058594 L 13.113281 19.582031 C 13.050781 19.683594 13.070312 19.8125 13.160156 19.890625 L 13.707031 20.359375 C 13.800781 20.4375 13.929688 20.429688 14.015625 20.351562 L 14.820312 20.820312 L 14.820312 22.972656 L 15.214844 22.917969 Z M 15.214844 21.050781 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 19.078125 39.183594 L 16.960938 39.183594 L 17.699219 27.042969 L 18.289062 27.042969 L 18.832031 25.816406 L 18.832031 22.832031 L 16.476562 23.109375 C 16.738281 23.308594 16.921875 23.605469 16.96875 23.953125 C 17.0625 24.644531 16.582031 25.28125 15.902344 25.375 L 11.90625 25.925781 C 11.851562 25.933594 11.792969 25.9375 11.738281 25.9375 C 11.359375 25.9375 11.007812 25.769531 10.769531 25.472656 L 9.796875 24.269531 L 9.457031 25.570312 L 9.457031 25.816406 L 10 27.042969 L 10.585938 27.042969 L 10.816406 30.78125 L 11.878906 31.972656 C 12.160156 32.289062 12.320312 32.695312 12.332031 33.117188 L 12.503906 39.09375 C 12.511719 39.328125 12.472656 39.554688 12.394531 39.765625 L 19.078125 39.765625 Z M 19.078125 39.183594 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 10.257812 15.640625 C 10.445312 15.925781 10.574219 16.238281 10.636719 16.574219 C 10.703125 16.910156 10.703125 17.246094 10.636719 17.582031 C 10.570312 17.917969 10.441406 18.230469 10.253906 18.515625 C 10.066406 18.800781 9.828125 19.035156 9.546875 19.226562 C 9.265625 19.417969 8.957031 19.546875 8.625 19.613281 C 8.292969 19.679688 7.960938 19.675781 7.628906 19.609375 C 7.296875 19.542969 6.988281 19.414062 6.707031 19.222656 C 6.425781 19.03125 6.191406 18.792969 6.003906 18.507812 C 5.816406 18.222656 5.691406 17.910156 5.625 17.574219 C 5.558594 17.238281 5.558594 16.902344 5.625 16.566406 C 5.691406 16.230469 5.820312 15.917969 6.007812 15.632812 C 6.199219 15.347656 6.433594 15.109375 6.714844 14.921875 C 6.996094 14.730469 7.304688 14.601562 7.636719 14.535156 C 7.96875 14.46875 8.300781 14.46875 8.632812 14.539062 C 8.964844 14.605469 9.273438 14.734375 9.554688 14.925781 C 9.835938 15.117188 10.070312 15.355469 10.257812 15.640625 Z M 10.257812 15.640625 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <g clipPath="url(#3b91e8ea8c)">
              <path
                d="M 3.976562 20.859375 L 2.316406 27.222656 C 2.164062 27.574219 2.148438 27.992188 2.3125 28.375 L 4.476562 33.398438 L 2.074219 38.65625 C 1.742188 39.378906 2.054688 40.230469 2.765625 40.566406 C 2.957031 40.65625 3.160156 40.699219 3.359375 40.699219 C 3.898438 40.699219 4.410156 40.390625 4.652344 39.863281 L 7.320312 34.019531 C 7.488281 33.648438 7.496094 33.21875 7.332031 32.84375 L 6.371094 30.605469 L 9.167969 33.738281 L 9.324219 39.1875 C 9.347656 39.964844 9.976562 40.582031 10.742188 40.582031 C 10.757812 40.582031 10.769531 40.582031 10.785156 40.582031 C 11.570312 40.558594 12.1875 39.898438 12.164062 39.105469 L 11.992188 33.128906 C 11.984375 32.785156 11.851562 32.457031 11.625 32.203125 L 8.332031 28.519531 L 9.636719 23.527344 L 11.035156 25.257812 C 11.207031 25.472656 11.46875 25.59375 11.738281 25.59375 C 11.777344 25.59375 11.820312 25.589844 11.859375 25.585938 L 15.855469 25.035156 C 16.351562 24.964844 16.699219 24.503906 16.632812 24 C 16.566406 23.496094 16.109375 23.144531 15.609375 23.210938 L 12.117188 23.691406 L 9.6875 20.6875 C 9.523438 20.484375 9.292969 20.371094 9.054688 20.351562 L 5.246094 19.480469 L 4.972656 19.0625 L 3.902344 17.449219 L 6.46875 11.6875 C 6.675781 11.226562 6.472656 10.679688 6.015625 10.472656 C 5.558594 10.261719 5.019531 10.46875 4.8125 10.929688 L 2.039062 17.15625 C 1.910156 17.445312 1.9375 17.785156 2.109375 18.046875 L 3.722656 20.484375 L 3.933594 20.800781 C 3.945312 20.820312 3.960938 20.839844 3.976562 20.859375 Z M 3.976562 20.859375 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <path
              d="M 13.414062 7.648438 L 9.484375 13.964844 L 9.71875 14.203125 L 13.890625 8.179688 Z M 13.414062 7.648438 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 10.707031 15.207031 L 17.539062 12.707031 L 17.449219 11.988281 L 10.652344 14.875 Z M 10.707031 15.207031 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <g clipPath="url(#7ff4871646)">
              <path
                d="M 8.976562 6.441406 L 8.296875 6.222656 L 8 13.675781 L 8.320312 13.765625 Z M 8.976562 6.441406 "
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
            <path
              d="M 37.273438 16.21875 C 37.097656 16.175781 36.921875 16.28125 36.878906 16.460938 L 36.398438 18.417969 C 36.371094 18.53125 36.402344 18.648438 36.480469 18.730469 L 37.585938 19.886719 L 37.585938 22.285156 L 37.609375 22.6875 L 37.863281 26.652344 C 37.882812 26.933594 38.125 27.148438 38.402344 27.128906 C 38.515625 27.121094 38.613281 27.078125 38.691406 27.011719 C 38.773438 27.078125 38.871094 27.121094 38.984375 27.128906 C 39.261719 27.148438 39.503906 26.933594 39.523438 26.652344 L 39.746094 23.179688 C 39.789062 23.210938 39.839844 23.226562 39.898438 23.230469 C 39.90625 23.230469 39.914062 23.234375 39.921875 23.234375 C 40.089844 23.234375 40.234375 23.097656 40.246094 22.921875 C 40.421875 20.222656 39.816406 19.582031 39.695312 19.480469 L 39.691406 19.480469 C 39.636719 19.433594 39.566406 19.402344 39.484375 19.402344 L 38.035156 19.402344 L 37.078125 18.398438 L 37.515625 16.621094 C 37.558594 16.441406 37.449219 16.261719 37.273438 16.21875 Z M 37.273438 16.21875 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 39.34375 17.574219 C 39.523438 17.753906 39.613281 17.976562 39.613281 18.234375 C 39.613281 18.492188 39.523438 18.710938 39.34375 18.894531 C 39.164062 19.074219 38.949219 19.167969 38.691406 19.167969 C 38.4375 19.167969 38.222656 19.074219 38.042969 18.894531 C 37.859375 18.710938 37.769531 18.492188 37.769531 18.234375 C 37.769531 17.976562 37.859375 17.753906 38.042969 17.574219 C 38.222656 17.390625 38.4375 17.300781 38.691406 17.300781 C 38.949219 17.300781 39.164062 17.390625 39.34375 17.574219 Z M 39.34375 17.574219 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 28.765625 16.371094 L 28.234375 18.558594 C 28.203125 18.679688 28.238281 18.8125 28.324219 18.902344 L 29.550781 20.1875 L 29.550781 22.875 L 29.578125 23.320312 L 29.863281 27.75 C 29.882812 28.066406 30.152344 28.304688 30.464844 28.285156 C 30.589844 28.277344 30.699219 28.226562 30.789062 28.152344 C 30.878906 28.226562 30.988281 28.277344 31.113281 28.285156 C 31.425781 28.304688 31.695312 28.066406 31.714844 27.75 L 31.996094 23.320312 L 32.027344 22.875 L 32.027344 20.1875 L 33.253906 18.902344 C 33.339844 18.8125 33.375 18.679688 33.34375 18.558594 L 32.8125 16.371094 C 32.761719 16.171875 32.5625 16.050781 32.367188 16.097656 C 32.171875 16.148438 32.050781 16.347656 32.101562 16.546875 L 32.585938 18.535156 L 31.515625 19.65625 L 30.0625 19.65625 L 28.992188 18.535156 L 29.476562 16.546875 C 29.527344 16.347656 29.40625 16.148438 29.210938 16.097656 C 29.011719 16.050781 28.816406 16.171875 28.765625 16.371094 Z M 28.765625 16.371094 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 31.816406 18.351562 C 31.816406 18.488281 31.792969 18.621094 31.738281 18.75 C 31.6875 18.875 31.613281 18.988281 31.515625 19.085938 C 31.421875 19.183594 31.308594 19.257812 31.183594 19.3125 C 31.054688 19.367188 30.925781 19.390625 30.789062 19.390625 C 30.652344 19.390625 30.519531 19.367188 30.394531 19.3125 C 30.269531 19.257812 30.15625 19.183594 30.0625 19.085938 C 29.964844 18.988281 29.890625 18.875 29.835938 18.75 C 29.785156 18.621094 29.757812 18.488281 29.757812 18.351562 C 29.757812 18.210938 29.785156 18.078125 29.835938 17.949219 C 29.890625 17.824219 29.964844 17.710938 30.0625 17.613281 C 30.15625 17.515625 30.269531 17.441406 30.394531 17.386719 C 30.519531 17.335938 30.652344 17.308594 30.789062 17.308594 C 30.925781 17.308594 31.054688 17.335938 31.183594 17.386719 C 31.308594 17.441406 31.421875 17.515625 31.515625 17.613281 C 31.613281 17.710938 31.6875 17.824219 31.738281 17.949219 C 31.792969 18.078125 31.816406 18.210938 31.816406 18.351562 Z M 31.816406 18.351562 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 22.445312 18.945312 L 21.476562 17.929688 L 21.914062 16.121094 C 21.960938 15.941406 21.851562 15.757812 21.671875 15.714844 C 21.492188 15.671875 21.3125 15.78125 21.269531 15.960938 L 20.785156 17.949219 C 20.757812 18.058594 20.789062 18.179688 20.867188 18.261719 L 21.984375 19.429688 L 21.984375 21.871094 L 22.007812 22.277344 L 22.265625 26.300781 C 22.285156 26.585938 22.53125 26.804688 22.8125 26.785156 C 22.925781 26.777344 23.027344 26.734375 23.105469 26.667969 C 23.1875 26.734375 23.289062 26.777344 23.402344 26.785156 C 23.683594 26.804688 23.929688 26.585938 23.949219 26.300781 L 24.207031 22.277344 L 24.230469 21.871094 L 24.230469 19.429688 L 25.347656 18.261719 C 25.425781 18.179688 25.457031 18.058594 25.429688 17.949219 L 24.945312 15.960938 C 24.902344 15.78125 24.71875 15.671875 24.542969 15.714844 C 24.363281 15.757812 24.253906 15.941406 24.300781 16.121094 L 24.738281 17.929688 L 23.769531 18.945312 Z M 22.445312 18.945312 "
              fillOpacity="1"
              fillRule="nonzero"
            />
            <path
              d="M 24.019531 17.539062 C 24.078125 17.796875 24.039062 18.035156 23.902344 18.253906 C 23.765625 18.476562 23.574219 18.621094 23.324219 18.679688 C 23.070312 18.738281 22.835938 18.699219 22.617188 18.5625 C 22.394531 18.425781 22.257812 18.230469 22.195312 17.976562 C 22.136719 17.722656 22.175781 17.484375 22.3125 17.261719 C 22.449219 17.039062 22.640625 16.898438 22.890625 16.835938 C 23.144531 16.777344 23.378906 16.816406 23.597656 16.953125 C 23.820312 17.089844 23.957031 17.285156 24.019531 17.539062 Z M 24.019531 17.539062 "
              fillOpacity="1"
              fillRule="nonzero"
            />
          </svg>
          <span>Super Campaigns</span>
        </button>

        <div
          className={
            dropdownSale
              ? "dropdown-menu dark:bg-jacarta-800 z-10 min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show absolute top-full right-0"
              : "dropdown-menu dark:bg-jacarta-800 z-10 hidden min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl absolute top-full right-0"
          }
        >
          <ul className="flex flex-col flex-wrap">
            {superchainData.map(({ id, text }) => {
              return (
                <li key={id}>
                  <button
                    className="dropdown-item font-display dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                    onClick={() => setsortActive(id)}
                  >
                    <span className="text-jacarta-700 dark:text-white">
                      {text}
                    </span>
                    {sortActive === id && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="fill-accent mb-[3px] h-4 w-4"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                      </svg>
                    )}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  } else if (dropdownFor === "price-range") {
    const currencyData = [
      {
        id: 1,
        image: "/images/chains/ETH.png",
        text: "ETH",
      },
      {
        id: 2,
        image: "/images/chains/FLOW.png",
        text: "FLOW",
      },
      {
        id: 3,
        image: "/images/chains/FUSD.png",
        text: "FUSD",
      },
      {
        id: 4,
        image: "/images/chains/XTZ.png",
        text: "XTZ",
      },
      {
        id: 5,
        image: "/images/chains/DAI.png",
        text: "DAI",
      },
      {
        id: 6,
        image: "/images/chains/RARI.png",
        text: "RARI",
      },
    ];
    return (
      <div className="my-1 mr-2.5">
        <Tippy
          animation="fade"
          arrow={false}
          trigger="click"
          interactive="true"
          placement="bottom"
          className="tooltip-container"
          content={
            <div
              className="dropdown-menu dark:bg-jacarta-800 z-10 min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show"
              aria-labelledby="priceRangeFilter"
            >
              {/* <!-- Blockchain --> */}
              <div className="dropdown mb-4 cursor-pointer px-5 pt-2 relative">
                <button
                  className="currency-dropdown dark:bg-jacarta-700 dropdown-toggle border-jacarta-100 dark:border-jacarta-600 flex items-center justify-between rounded-lg border py-3.5 px-3 text-sm dark:text-white w-full"
                  onClick={handleCurrencyDropdown}
                >
                  <span className="font-display flex items-center">
                    <Image
                      width={24}
                      height={24}
                      src="/images/chains/ETH.png"
                      alt="eth"
                      className="mr-2 h-5 w-5 rounded-full"
                    />
                    ETH
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-500 h-4 w-4 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                  </svg>
                </button>

                <div
                  className={
                    currencyDropdown
                      ? "dark:bg-jacarta-800 z-10 min-w-[252px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show absolute top-full right-0"
                      : "dark:bg-jacarta-800 z-10 min-w-[252px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl absolute top-full right-0 hidden"
                  }
                >
                  {currencyData.map(({ id, text, image }) => {
                    return (
                      <button
                        key={id}
                        className="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                        onClick={() => setCurrencyDropdown(false)}
                      >
                        <span className="flex items-center">
                          <Image
                            width={24}
                            height={24}
                            src={image}
                            alt="eth"
                            className="mr-2 h-5 w-5 rounded-full"
                          />
                          {text}
                        </span>
                      </button>
                    );
                  })}
                </div>
              </div>

              {/* <!-- From / To --> */}
              <div className="flex items-center space-x-3 px-5 pb-2">
                <input
                  type="number"
                  placeholder="From"
                  value={currencyValFrom}
                  onChange={(e) => handleCurrencyValFrom(e)}
                  className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full max-w-[7.5rem] rounded-lg border py-[0.6875rem] px-4 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                />
                <input
                  type="number"
                  placeholder="To"
                  value={currencyValTo}
                  onChange={(e) => handleCurrencyValTo(e)}
                  className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full max-w-[7.5rem] rounded-lg border py-[0.6875rem] px-4 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                />
              </div>

              <div className="dark:border-jacarta-600 border-jacarta-100 -ml-2 -mr-2 mt-4 flex items-center justify-center space-x-3 border-t px-7 pt-4">
                <button
                  type="button"
                  className="text-accent shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume flex-1 rounded-full bg-white py-2 px-6 text-center text-sm font-semibold transition-all hover:text-white"
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark flex-1 rounded-full py-2 px-6 text-center text-sm font-semibold text-white transition-all"
                >
                  Apply
                </button>
              </div>
            </div>
          }
        >
          <button
            className="group dropdown-toggle dark:border-jacarta-600 dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent border-jacarta-100 font-display text-jacarta-700 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white"
            id="priceRangeFilter"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M17 16h2V4H9v2h8v10zm0 2v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3zM5.003 8L5 20h10V8H5.003zM7 16h4.5a.5.5 0 1 0 0-1h-3a2.5 2.5 0 1 1 0-5H9V9h2v1h2v2H8.5a.5.5 0 1 0 0 1h3a2.5 2.5 0 1 1 0 5H11v1H9v-1H7v-2z"></path>
            </svg>
            <span>Price Range</span>
          </button>
        </Tippy>
      </div>
    );
  } else if (dropdownFor === "last7Days-ranks") {
    return (
      <div className="dropdown relative my-1 cursor-pointer">
        <button
          className="dark:bg-jacarta-700 itemDateDropdown dropdown-toggle border-jacarta-100 dark:border-jacarta-600 inline-flex w-48 items-center justify-between rounded-lg border bg-white py-2 px-3 text-sm dark:text-white"
          onClick={handleItemDateDropdown}
        >
          <span className="font-display">Last 7 Days</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-500 h-4 w-4 dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
          </svg>
        </button>

        <div
          className={
            itemDateDropdown
              ? "dropdown-menu dark:bg-jacarta-800 z-10 whitespace-nowrap rounded-xl max-w-xs w-[13rem] bg-white py-4 px-2 text-left shadow-xl show absolute top-full right-0"
              : "dropdown-menu dark:bg-jacarta-800 z-10 whitespace-nowrap rounded-xl max-w-xs w-[13rem] bg-white py-4 px-2 text-left shadow-xl hidden absolute top-full right-0"
          }
        >
          {data.map(({ id, text }) => {
            return (
              <button
                key={id}
                onClick={() => {
                  setsortActive(id);
                  dispatch(updateRenkingDataByPostdate(text));
                }}
                className="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
              >
                {text}
                {sortActive === id && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-accent mb-[3px] h-4 w-4"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                  </svg>
                )}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
};

export default Recently_added_dropdown;
